import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'

export default function useReferral() {
  const location = useLocation()
  const history = useHistory()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const refParam = searchParams.get('ref')
    const mbsySourceParam = searchParams.get('mbsy_source')
    const campaignIdParam = searchParams.get('campaignid')
    const mbsyParam = searchParams.get('mbsy')
    if (refParam) {
      localStorage.setItem('eatright-ref', refParam)
      searchParams.delete('ref')

      history.replace({
        search: searchParams.toString()
      })
    }
    if (mbsySourceParam && campaignIdParam && mbsyParam) {
      localStorage.setItem('eatright-mbsy-source', mbsySourceParam)
      localStorage.setItem('eatright-mbsy-campaignid', campaignIdParam)
      localStorage.setItem('eatright-mbsy', mbsyParam)
    }
  }, [location.search])
}
