import { ReactComponent as TrainxIcon } from '../assets/media/trainx-logo.svg'
import trainxfavicon from '../assets/media/favicon-trainx.ico'
import { BrandingType } from '../types/branding.type'

const EAT_RIGHT_BRANDING: BrandingType = {
  primaryColor: '#96BE35',
  primaryLightColor: '#84a72f',
  primaryColor_2: '#f4f8ea',
  primaryColor_3: '',
  link: '#2E81ED',
  logo: TrainxIcon,
  icon: trainxfavicon,
  name: 'TrainxFood',
  multiple_accounts: false,
  showUserInfo: false
}

const LIVE_RIGHT_BRANDING: BrandingType = {
  primaryColor: '#f18318',
  primaryLightColor: '#ead7c5',
  primaryColor_2: '#ead7c5',
  primaryColor_3: '#FDB6B7',
  link: '#2E81ED',
  logo: TrainxIcon,
  icon: trainxfavicon,
  name: 'Trainx',
  multiple_accounts: true,
  showUserInfo: true
}

const LIVE_RIGHT_TRAINER_BRANDING: BrandingType = {
  primaryColor: '#3FC9AD',
  primaryLightColor: '#8bdecd',
  primaryColor_2: '#e3f7f3',
  primaryColor_3: '#e3f7f3',
  link: '#E49A0A',
  logo: TrainxIcon,
  icon: trainxfavicon,
  name: 'Trainx',
  multiple_accounts: true,
  showUserInfo: true
}

type Brands = { [key: string]: BrandingType }

const brands: Brands = {
  default: LIVE_RIGHT_BRANDING,
  localhost: LIVE_RIGHT_BRANDING,
  'identity.app.trainx.fitness': LIVE_RIGHT_BRANDING,
  'invoices.app.trainx.fitness': LIVE_RIGHT_BRANDING,
  'payments.app.trainx.fitness': LIVE_RIGHT_BRANDING,
  'identity.food.trainx.fitness': EAT_RIGHT_BRANDING,
  'invoices.food.trainx.fitness': EAT_RIGHT_BRANDING,
  'payments.food.trainx.fitness': EAT_RIGHT_BRANDING,
  'identity.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING,
  'invoices.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING,
  'payments.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING
}

export function getBrand(isClient?: boolean): BrandingType {
  const LIVE_RIGHT_BRAND =
    typeof isClient === 'boolean'
      ? isClient
        ? LIVE_RIGHT_BRANDING
        : LIVE_RIGHT_TRAINER_BRANDING
      : LIVE_RIGHT_BRANDING

  const brands: Brands = {
    default: LIVE_RIGHT_BRAND,
    localhost: EAT_RIGHT_BRANDING,
    'identity.app.trainx.fitness': LIVE_RIGHT_BRANDING,
    'invoices.app.trainx.fitness': LIVE_RIGHT_BRANDING,
    'payments.app.trainx.fitness': LIVE_RIGHT_BRANDING,
    'identity.food.trainx.fitness': EAT_RIGHT_BRANDING,
    'invoices.food.trainx.fitness': EAT_RIGHT_BRANDING,
    'payments.food.trainx.fitness': EAT_RIGHT_BRANDING,
    'identity.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING,
    'invoices.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING,
    'payments.foodstaging.trainx.fitness': EAT_RIGHT_BRANDING
  }

  return NODE_ENV === 'development' && REACT_APP_LOCAL_DEV_MODE === 'liveright'
    ? brands.default
    : brands[document.location.hostname]
}

const branding = new Proxy(brands, {
  get: (target, prop: string) => target[prop] || target['default']
})

const { NODE_ENV, REACT_APP_LOCAL_DEV_MODE } = process.env

const brand: BrandingType =
  NODE_ENV === 'development' && REACT_APP_LOCAL_DEV_MODE === 'liveright'
    ? branding.default
    : branding[document.location.hostname]

;(document.head.querySelector('[rel="icon"]') as HTMLLinkElement).href =
  brand.icon

export default brand
