import React, { createContext, useEffect, useState } from 'react'

import { EP_GET_USER } from '../../enums/api.enum'
import { AuthResponseType } from '../../hooks/authorization.hook'
import api from '../../managers/api.manager'
import cookieManager from '../../managers/cookie.manager'
import logger from '../../managers/logger.manager'

export const AuthDataContext = createContext<{
  data: AuthResponseType | null
  setData: (data: AuthResponseType | null) => void
}>({ data: null, setData: () => {} })

export const AuthDataProvider = ({ children }: { children: any }) => {
  const access_token = cookieManager.get('access_token')
  const [data, setData] = useState<AuthResponseType | null>(
    access_token
      ? {
          access_token,
          user: {
            accounts: [],
            avatar: null,
            birthday: null,
            created_at: '',
            email: '',
            email_verified_at: null,
            first_name: '',
            gender: null,
            is_active: true,
            last_name: '',
            uuid: '',
            city: null,
            country: null,
            region_name: '',
            postal_code: ''
          }
        }
      : null
  )

  useEffect(() => {
    api
      .get(EP_GET_USER)
      .then((res) => res.data.data)
      .then((res) => {
        const d = data as AuthResponseType
        d.user = res

        setData({ ...d })

        logger.info('SETTING COOKIE 2')
        cookieManager.set('auth', JSON.stringify(res))
      })
  }, [])

  return (
    <AuthDataContext.Provider
      value={{
        data,
        setData: (data: AuthResponseType | null) => {
          setData(data)
          data && cookieManager.set('access_token', data?.access_token)
          data && cookieManager.set('auth', JSON.stringify(data?.user))
        }
      }}
    >
      {children}
    </AuthDataContext.Provider>
  )
}
