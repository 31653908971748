import React, { createContext, useState } from 'react'

import { InvoiceFullType } from '../../types/invoice.type'

export type PaidInvoiceContextType = {
  paidInvoice: InvoiceFullType | null
  setPaidInvoice: (invoice: InvoiceFullType) => void
}

export const PaidInvoiceContext = createContext<PaidInvoiceContextType | null>(
  null
)
export const PaidInvoiceProvider = ({ children }: { children: any }) => {
  const [paidInvoice, setPaidInvoice] = useState<InvoiceFullType | null>(null)

  return (
    <PaidInvoiceContext.Provider value={{ paidInvoice, setPaidInvoice }}>
      {children}
    </PaidInvoiceContext.Provider>
  )
}
