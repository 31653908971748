export const mainHost = () => {
  if (document.location.host.startsWith('localhost')) {
    return 'http://localhost:8022'
  }

  if (document.location.host.includes('theliveright')) {
    return 'https://app.theliveright.com'
  }

  return (
    document.location.protocol +
    '//' +
    document.location.hostname.split('.').slice(1).join('.')
  )
}
